// config

import Swiper, { Navigation, Pagination, Autoplay, Thumbs } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';

Swiper.use([Navigation, Pagination, Autoplay, Thumbs]);

// build

var productHomeSwiper = new Swiper('#productHomeSwiper .mySwiper', {
	breakpoints: {
		320: {
			slidesPerView: 1,
			spaceBetween: 10
		},
		768: {
			slidesPerView: 2,
			spaceBetween: 10
		},
		992: {
			slidesPerView: 2,
			spaceBetween: 15
		},
		1200: {
			slidesPerView: 3,
			spaceBetween: 15
		}
	},
	navigation: {
		nextEl: '#productHomeSwiper .swiper-button-next',
		prevEl: '#productHomeSwiper .swiper-button-prev'
	}
});

// khách hàng tin dùng

var certificateSwiper = new Swiper('#certificateSwiper .mySwiper', {
	breakpoints: {
		320: {
			slidesPerView: 1,
			spaceBetween: 15
		},
		768: {
			slidesPerView: 2,
			spaceBetween: 20
		},
		992: {
			slidesPerView: 3,
			spaceBetween: 15,
			centeredSlides: true,
			centeredSlidesBounds: true
		},
		1200: {
			slidesPerView: 3,
			spaceBetween: 30,
			centeredSlides: true,
			centeredSlidesBounds: true
		}
	},
	navigation: {
		nextEl: '#certificateSwiper .swiper-button-next',
		prevEl: '#certificateSwiper .swiper-button-prev'
	}
});

// clients review

var clientsReviewSwiper = new Swiper('#clientsReviewSwiper .mySwiper', {
	autoplay: true
	// spaceBetween: 15
});

// timeline swiper

var timelineSwiperThumb = new Swiper('#timelineSwiper .mySwiper', {
	observer: true,
	observeParents: true,
	breakpoints: {
		320: {
			slidesPerView: 3,
			spaceBetween: 10
		},
		768: {
			slidesPerView: 5,
			spaceBetween: 15
		},
		1200: {
			slidesPerView: 5,
			spaceBetween: 25
		}
	},
	navigation: {
		nextEl: '#timelineSwiper .swiper-button-next',
		prevEl: '#timelineSwiper .swiper-button-prev'
	}
});

var timelineSwiper = new Swiper('#timelineSwiperThumb .mySwiper ', {
	loop: false,
	spaceBetween: 10,
	observer: true,
	observeParents: true,
	observer: true,
	observeParents: true,
	thumbs: {
		swiper: timelineSwiperThumb
	}
});

// product page banner ads

var bannerProductAds = new Swiper('#bannerProductAds .mySwiper', {
	spaceBetween: 15,
	autoplay: true,
	navigation: {
		nextEl: '#bannerProductAds .swiper-button-next',
		prevEl: '#bannerProductAds .swiper-button-prev'
	}
});

// product detail sync slide

var imagePreviewThumbs = new Swiper('#imagePreviewThumbs .mySwiper', {
	breakpoints: {
		320: {
			slidesPerView: 4,
			spaceBetween: 10
		},
		768: {
			slidesPerView: 4,
			spaceBetween: 18
		}
	}
});

var imagePreview = new Swiper('#imagePreview .mySwiper', {
	spaceBetween: 15,
	thumbs: {
		swiper: imagePreviewThumbs
	}
});

// detail tin tức - ads sidebar

var posterAds = new Swiper('#posterAds .mySwiper', {
	// autoplay:true,
	breakpoints: {
		320: {
			slidesPerView: 1,
			spaceBetween: 15
		},
		768: {
			slidesPerView: 2,
			spaceBetween: 15
		},
		992: {
			slidesPerView: 1,
			spaceBetween: 15
		}
	},
	pagination: {
		el: '#posterAds .swiper-pagination'
	}
});
