// lodash
import _ from 'lodash';

// fontawesome
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';

// compiled sass
import '../scss/style.scss';

// fancybox
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox.css';

// tw elements
import 'tw-elements';

// flatpickr
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';
import { Vietnam } from 'flatpickr/dist/l10n/vn.js';

// jquery , jquery ui
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.css';

// aos
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init({
	disable: 'phone',
	startEvent: 'DOMContentLoaded',
	duration: 1000,
	easing: 'ease',
	anchorPlacement: 'top-bottom'
});
