const unalertInputRequired = () => {
	document.addEventListener(
		'invalid',
		(function () {
			return function (e) {
				e.preventDefault();
				document.getElementsByClassName('unalert').focus();
			};
		})(),
		true
	);
};

const initFlatpickr = () => {
	flatpickr('#flatpickr-date', {
		dateFormat: 'd-m-Y',
		minDate: 'today',
		locale: 'vn'
	});

	flatpickr('#flatpickr-time', {
		enableTime: true,
		noCalendar: true,
		dateFormat: 'H:i',
		time_24hr: true,
		locale: 'vn'
	});
};

const headerSticky = () => {
	let header = document.querySelector('#header');
	let main = document.querySelector('main');
	let headerHeight = header.clientHeight;
	console.log(
		'🚀 ~ file: main.js:34 ~ headerSticky ~ headerHeight',
		headerHeight
	);

	header.classList.add('scroll');
	main.style.marginTop = headerHeight + 'px';
};

const range = () => {
	$('#slider-range').slider({
		range: true,
		step: 500000,
		min: 0,
		max: 10000000,
		values: [0, 10000000],
		slide: function (event, ui) {
			$('#amount-from').text(formatNumber(ui.values[0]));
			$('#amount-to').text(formatNumber(ui.values[1]));
		}
	});
	$('#amount-from').text(formatNumber($('#slider-range').slider('values', 0)));

	$('#amount-to').text(formatNumber($('#slider-range').slider('values', 1)));
};

const formatNumber = (value) => {
	var b = parseFloat(value)
		.toFixed(2)
		.replace(/(\d)(?=(\d{3})+\.)/g, '$1.')
		.toString();
	var len = b.length;

	b = b.substring(0, len - 3);
	return b;
};

const quantityCounter = () => {
	let btnDecrement = document.getElementsByClassName('decrement');
	let btnIncrement = document.getElementsByClassName('increment');

	for (var i = 0; i < btnIncrement.length; i++) {
		btnIncrement[i].addEventListener('click', (event) => {
			var btnCliked = event.target;
			var input = btnCliked.parentElement.children[1];
			var newVal = parseInt(input.value) + 1;
			input.value = newVal;
		});
	}

	for (var i = 0; i < btnDecrement.length; i++) {
		btnDecrement[i].addEventListener('click', (event) => {
			var btnCliked = event.target;
			var input = btnCliked.parentElement.children[1];

			if (input.value <= 1) {
				alert('Tối thiếu 01 số lượng trên mỗi sản phẩm trong giỏ hàng');
				btnDecrement[i].disabled = true;
			} else {
				var newVal = parseInt(input.value) - 1;
				input.value = newVal;
			}
		});
	}
};

const productDetailTabs = () => {
	let tabLink = document.querySelectorAll('#tabProductDetail .item');
	let tabContent = document.querySelectorAll('#tabProductDetailContent .item');

	for (var i = 0; i < tabLink.length; i++) {
		tabLink[0].classList.add('active');
		tabContent[0].classList.add('active');

		tabLink[i].addEventListener('click', (e) => {
			e.preventDefault();
			let clicked = e.currentTarget;

			for (var i = 0; i < tabLink.length; i++) {
				tabLink[i].classList.remove('active');
			}

			clicked.classList.add('active');

			for (var i = 0; i < tabContent.length; i++) {
				tabContent[i].classList.remove('active');
			}

			let getID = clicked.getAttribute('href');
			let queryID = document.querySelector(getID);
			queryID.classList.add('active');

			// check height content per tab to add " xem thêm "
			const childrenBtn = queryID.children[1];
			const childrenParagraph = queryID.children[0];
			const childrenHeight = queryID.children[0].clientHeight;
			const sidebarHeight = document.querySelector(
				'#sidebarProductDetail'
			).clientHeight;
			const tabLinks = document.querySelector('#tabProductDetail');
			let tabLinksHeight = tabLinks.offsetHeight;
			tabLinksHeight += parseInt(
				window.getComputedStyle(tabLinks).getPropertyValue('margin-top')
			);
			tabLinksHeight += parseInt(
				window.getComputedStyle(tabLinks).getPropertyValue('margin-bottom')
			);

			if (childrenHeight + tabLinksHeight > sidebarHeight) {
				childrenParagraph.style.maxHeight =
					sidebarHeight - tabLinksHeight * 2 + 'px';
				childrenParagraph.classList.add('overflow-hidden');
				childrenBtn.classList.add('active');
			}
		});
	}
};

const iconInterval = () => {
	let wrapper = document.querySelector('#getInterval');
	let item = document.querySelectorAll('#getInterval img');

	const runInterval = () => {
		for (var i = 0; i < item.length; i++) {
			setTimeout(() => {
				item[0].classList.add('active');
			}, 0);

			setTimeout(() => {
				item[0].classList.remove('active');
			}, 500);

			setTimeout(() => {
				item[1].classList.add('active');
			}, 1000);

			setTimeout(() => {
				item[1].classList.remove('active');
			}, 1500);

			setTimeout(() => {
				item[2].classList.add('active');
			}, 2000);

			setTimeout(() => {
				item[2].classList.remove('active');
			}, 2500);
		}
	};

	const runningUp = setInterval(runInterval, 3000);
};

const fixedsocialPopup = () => {
	const itemclicked = document.querySelector('.js-item-single');

	itemclicked.addEventListener('click', (e) => {
		let clicked = e.currentTarget;

		clicked.nextElementSibling.classList.add('active');
	});

	var ignoreMe = document.querySelector('#fixedSocialList');
	window.addEventListener('mouseup', function (event) {
		if (
			event.currentTarget != ignoreMe &&
			event.currentTarget.parentNode != ignoreMe
		) {
			ignoreMe.classList.remove('active');
		}
	});
};

window.addEventListener('load', () => {
	unalertInputRequired();
	headerSticky();
	iconInterval();
	initFlatpickr();
	// range();
	quantityCounter();
	productDetailTabs();
	fixedsocialPopup();
});
